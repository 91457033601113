import React from 'react'
import loadable from '@loadable/component'
import MetaData from '../components/MetaData'
import { Container, Row, Col } from 'react-bootstrap'
import PageWrapper from '../components/PageWrapper'
import { Title, Section } from '../components/Core'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const OrdersTable = loadable(() => import('../components/Table/OrdersTable'))

const Orders = props => {
  const { t, ready } = useTranslation(['orders'], { useSuspense: false })
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false

  return ready && (
    <>
      <MetaData page="default" />
      <PageWrapper headerDark footerDark>
        <Section bg={userDarkMode ? 'black' : 'bg'} pb={[0, null, null, 0]}>
          <Container>
            <Row>
              <Col lg="12">
                <Title
                  color={userDarkMode ? '#3986F7' : '#185398'}
                  fontSize={'28px !important'}
                  lineHeight={'28px !important'}
                  marginBottom={'0px !important'}
                  marginLeft={'0px !important'}
                  marginTop={3}
                  letterSpacing={'-0.02em'}
                >
                  {t('orders')}
                </Title>
                <OrdersTable />
              </Col>
            </Row>
          </Container>
        </Section>
      </PageWrapper>
    </>
  )
}

export default Orders
